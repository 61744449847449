if(window.location.href.indexOf("/activitytracker") > -1) {
   $('body').addClass('no-bb');
}

if (typeof breakpoint !== 'undefined') {

	var sharedVars = {
		fullMonths : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		displayPageNumbers : 5,
		itemsPerPage: 18,
		originalBreakpoint: breakpoint,
		initialMarkup: '',
		initialMarkupFlag: false
	}


	//this js should always be loaded after the resources center section.  The below check traverses the DOM and updates the styles if a resource center section isn't found. This can be moved to doc ready, but has less of a/no delay when fired as soon as the assets are available.
	if ($('.header-cme-rclist').length < 1) {
		$('.page-header').addClass('nrc');
	}


	if (typeof homepageCheck === 'undefined') {
		$('#brandingBar').addClass('nonhp');
	}

	var menuItems = {
		config : {
			savedActivityMarkup : '',
			savedSavedMarkup : '',
			noItemsSavedMarkup : "<span class='modal-header'>Save activities for later...</span><span class='modal-saved-text'>You haven't saved any activities just yet but once you do, they will show here so that you can easily access them whenever you're ready.</span>",
			trackerMarkup : '',
			savedObj : '',
			savedItemsCount: 0,
			trackerObj : '',
			savedActivitiesChanged: true,
			count: 0,
			trackerFlag : false
		},
		init : function() {
			menuItems.config.savedActivityMarkup = $('.tracker-modal').html();
			menuItems.config.savedSavedMarkup = $('.saved-modal').html();
			$('.tracker-modal').html('');
		},
		modalLayer : function(target) {
			var theTitle;
			var theContent;
			if (target === "tracker") {
				theTitle = "Activity Tracker";
				theContent = menuItems.config.savedActivityMarkup
			}
			else if (target === "saved") {
				theTitle = "Saved Activities";
				theContent = menuItems.config.savedSavedMarkup
			}
		
			$('#bp-one-modal-bg').off('click');
			$('body').prepend('<div id="bp-one-modal-bg"></div>');
			$('#bp-one-modal-bg').html('<span class="modal-top-layer"><span class="modal-spacer"></span>'+theTitle+'<span class="close-x"></span></span><span id="bp-one-modal-box"></span>')
			$('#bp-one-modal-box').html(theContent);
		},
		getTrackerInfo : function() {
			//TODO: get tracker info via API
		},
		loggedOut : {
			displayTracker : function(){ 	
				$('.tracker-modal').html(menuItems.config.savedActivityMarkup);
				$('.tracker-modal').addClass('active')
			},
			displaySaved : function() {
				$('.saved-modal').html(menuItems.config.savedSavedMarkup)
				$('.saved-modal').addClass('active')			
			},
			displayLoggedOutModal : function(target) {
				if (target === "tracker") {
					menuItems.modalLayer('tracker')
				}
				else if (target === "saved") {
					menuItems.modalLayer('saved');
				}
			}
		},
		loggedIn : {
			displayTracker : function(){
				if (menuItems.config.trackerMarkup === '' && menuItems.config.trackerFlag === false) { //only check once per page refresh
					if ($('#tracker .bucketContent').length > 0) { //if the content is already available via homepage & index page load, just copy the markup
						menuItems.config.trackerMarkup = $('#tracker .bucketContent').html();
						$('.tracker-modal').html(menuItems.config.trackerMarkup);
						$('.tracker-modal .morelink').on('click',function(){
							window.location = "/activitytracker";
						});
					}
					else if (typeof getActivities === "undefined"){ // if no markup is available and the getActivities script/function isn't loaded, ajax it in and run the function on success
						$.ajax({
							url: '//img.'+getDomain()+'medscapestatic.com/medscape-core/cme/js/homepage/getActivities.js',
							dataType: 'script',
							success: function(json){
								getActivities.getActivitiesByDate('.tracker-modal');
								menuItems.config.trackerFlag = true;
							},
							async: true
						});
					}
					else { //if no markup, but the script/function already exists, run it.
						getActivities.getActivitiesByDate('.tracker-modal');
						menuItems.config.trackerFlag = true;
					}
				}
				else {
					if ($('.tracker-modal').html() !== menuItems.config.trackerMarkup) {

					}
				}
				$('.tracker-modal').addClass('active');
			},
			displaySaved : function() {
				if (menuItems.config.savedActivitiesChanged === true) {
					var theResults = getUserActivities.get(3);

					$.each(theResults, function(key,value){
						if (key == 'count') {
							saved.config.count = value;
						}
						if (key == 'data') {
							saved.config.savedArray = value;
						}
					});
					
					if (saved.config.savedArray.length < 1) {
						$('.saved-modal').html(menuItems.config.noItemsSavedMarkup);
					}
					else {
						saved.bbMarkup();
						saved.reformatDates();
					}
					menuItems.config.savedItemsCount = 0;
					menuItems.config.savedActivitiesChanged = false;
				}
			},
			savedMarkup : function(title, href, specialty, date) {
			
			
			}
		},
		hideSaved : function(){
			$('.saved-modal').removeClass('active');
			$('#bp-one-modal-bg').off();
			$('#bp-one-modal-bg').remove();
		},
		hideTracker : function(){
			$('.tracker-modal').removeClass('active');
			$('#bp-one-modal-bg').off();
			$('#bp-one-modal-bg').remove();
		}
	}

	var scrollEvents = {
		stickyHeaderCheck : function(){
			if ($(document).scrollTop() > $('#brandingBarPlaceholder').offset().top) {
				$('#brandingBar').addClass('active');
			}
			else {
				$('#brandingBar.active').removeClass('active');
			}
			$('.tracker-modal, .saved-modal').removeClass('active');
		},
		
	}

	var brandingBinds = function(){
		$('#savedActivitiesBrandingBar').on('mouseenter',function(){
			if (Number(qrllog) !== 1) {
				menuItems.loggedOut.displaySaved();
			}
			else {
				menuItems.loggedIn.displaySaved();
				if (breakpoint !== 1) {
					$('.saved-modal').addClass('active');
				}
			}
			$('#bodypadding').on('mouseenter',function(){
				if (breakpoint !== 1) {
					menuItems.hideSaved();
				}
				$('#bodypadding').off();
			});
		});
		$('#activityTrackerBrandingBar').on('mouseenter',function(){
			if (Number(qrllog) !== 1) {
				menuItems.loggedOut.displayTracker();
			}
			else {
				menuItems.loggedIn.displayTracker();
			}
			$('#bodypadding').on('mouseenter',function(){
				if (breakpoint !== 1) {
					menuItems.hideTracker();
				}
				$('#bodypadding').off();
			});		
		});
		$('#savedActivitiesBrandingBar').on('click',function(){
			if (Number(qrllog) !== 1) {
				if (breakpoint === 1) {
					menuItems.loggedOut.displayLoggedOutModal('saved');
				}
				else {
					menuItems.loggedOut.displaySaved();
				}
			}
			else {
				if (breakpoint === 1) {
					window.location = "/sites/saved";
				}
				else {
					menuItems.loggedIn.displaySaved();
					if (breakpoint !== 1) {
    					$('.saved-modal').addClass('active');
    				}
				}
			}
		});
		$('#activityTrackerBrandingBar').on('click',function(){
			if (Number(qrllog) !== 1) {
				if (breakpoint === 1) {
					menuItems.loggedOut.displayLoggedOutModal('tracker');
				}
				else {
					menuItems.loggedOut.displayTracker();
				}
			}
			else {
				if (breakpoint === 1) {
					window.location = "/activitytracker";
				}
				else {		
					menuItems.loggedIn.displayTracker();
				}
			}		
		});
		/* //attach event to bodypadding for better mouseover experience - EB 8.22.17
		$('#savedActivitiesBrandingBar').on('mouseout',function(){
			if (breakpoint !== 1) {
				menuItems.hideSaved();
			}
		});

		$('#activityTrackerBrandingBar').on('mouseout',function(){
			if (breakpoint !== 1) {
				menuItems.hideTracker();
			}
		});
		*/
		$('body').on('click','#bp-one-modal-bg',function(){
			menuItems.hideTracker();
			menuItems.hideSaved();
		});
		
		$('.saved-modal').on('click', '.saveState.saved', function(event){
			$(this).closest('.saved-card-bb').addClass('fade-away');
			var self = this;
			setTimeout(function(){
				var txnId = Number($(self).attr('id').replace('txn','').replace('-bb',''));
				saveUserActivities.update(txnId).done(function(){
					menuItems.config.savedActivitiesChanged = true;
					menuItems.loggedIn.displaySaved();
					$('#txn'+ txnId + '-bb').removeClass('saved');
					$('#txn'+ txnId + '-bb').html('<span class="star-blue"></span><br />Save');
					$('#txn'+ txnId + '-bb').attr('id','');
					
					$('#txn'+ txnId + '-saved').removeClass('saved');
					$('#txn'+ txnId + '-saved').html('<span class="star-blue"></span><br />Save');
					$('#txn'+ txnId + '-saved').attr('id','');
					
					//temp fix - firing twice to account for duplicate elements hidden when extra-wide featured bar splits the masonry layout. Clean fix: When duplicate cards, add indicator and target both
					$('#txn'+ txnId + '-activity').removeClass('saved');
					$('#txn'+ txnId + '-activity').html('<span class="star-blue"></span><br />Save');
					$('#txn'+ txnId + '-activity').attr('id','');	
					$('#txn'+ txnId + '-activity').removeClass('saved');
					$('#txn'+ txnId + '-activity').html('<span class="star-blue"></span><br />Save');
					$('#txn'+ txnId + '-activity').attr('id','');					
					
				});
			},300);
		});
		$('.center-branding-text').on('click',function(){
			window.location = "//www."+getDomain()+"medscape.org";
		});
		$('#savedActivitiesBrandingBar').on('click','.activityTitle',function(){
			wmdTrack('bbar-save_art');
		});
	}


	var touchEnabled = {
		config : {
			isTouch : false
		},
		init : function() {
			//touch event check here
			  touchEnabled.config.isTouch = 'ontouchstart' in window        // works on most browsers 
				  || navigator.maxTouchPoints;       // works on IE10/11 and Surface
				if (touchEnabled.config.isTouch != true)
					touchEnabled.config.isTouch = false;
		}
	}


	var saved = {
		config : {
			savedArray : [],
			dropDownMarkup : ''
			
		},
		updateImgPath : function(img){
			var newImgPath = img.replace('professional_assets/medscape/images/','//img.'+getDomain()+'medscapestatic.com/');

			if (newImgPath.length < 1)
				newImgPath = '//img.'+getDomain()+'medscapestatic.com/pi/global/icons/placeholder.png'

				
			return newImgPath
		},
		reformatDates : function() {
			$('.saved-modal .reformatted-date').remove();
			$('.saved-modal .posting-date').each(function() {
				var self = this;
				var str = $(this).html();
				if (str.indexOf("-") >= 0) { //if date isn't already formatted
					var dateWithTime = $(this).html().split(' ');
					var thisProgramDate = dateWithTime[0].split('-');
					$(self).closest('.byline').append("<span class='reformatted-date'> ," + sharedVars.fullMonths[thisProgramDate[1] - 1] + " " + thisProgramDate[2] + ", " + thisProgramDate[0] + '</span>')
				}
				else {
					$(self).closest('.byline').append("<span class='reformatted-date'>" + $(self).closest('.byline .posting-date').html() + '</span>');
				}
			});
		},
		theAvailCredits : function(card) {
			var mocCounter = 0;
			var metaCreditArray = $(card.jsonData.creditarray);
			var creditsMarkup = '';

			if(card.jsonData.hasOwnProperty('priAccreditorId') && card.jsonData.priAccreditorId == 1073){
                let credits  = card.jsonData.creditarray[0].credits;
                return '<span class="primary-credits"><span class="credits-number">'+credits*60+' MIN </span>EBAC</span>'
            }
			
			var creditTypesArray = [];
			var creditsArray = [];
				
			$(card.jsonData.creditarray).each(function(){
				var self = this;
				$.each(Object.keys(this),function(i){
					if (this == "credittype") {
						var creditType = Number(Object.values(self)[i]);
						creditTypesArray.push(creditType);
					}
					else if (this == "credits") {
						var credits = Number(Object.values(self)[i]);
						creditsArray.push(credits);
					}
					else {
						//fallback to "credits" if misconfigured data
						var credits = Number(Object.values(self)[i]);
						creditsArray.push(credits);
					}
				});

			});

			var highestLength = (Math.max.apply(Math, creditsArray)) * 60;
			var mocCheck = creditTypesArray.indexOf(9);
			var cmeCheck = creditTypesArray.indexOf(1);
			
			//check each of the other 6 CE credit types.. if any exist, save the index value to target the credits in the next function. Only save 1 CE type.
			var ceCheck = creditTypesArray.indexOf(2)
			if (ceCheck < 0)
				ceCheck = creditTypesArray.indexOf(3);
			if (ceCheck < 0)
				ceCheck = creditTypesArray.indexOf(4);
			if (ceCheck < 0)
				ceCheck = creditTypesArray.indexOf(5);
			if (ceCheck < 0)
				ceCheck = creditTypesArray.indexOf(7);
			if (ceCheck < 0)
				ceCheck = creditTypesArray.indexOf(8);
			
			//format whole numbers
			if (creditsArray[mocCheck] % 1 === 0)
				creditsArray[mocCheck] = Number(creditsArray[mocCheck]).toFixed(1);
			if (creditsArray[ceCheck] % 1 === 0)
				creditsArray[ceCheck] = Number(creditsArray[ceCheck]).toFixed(1);
			if (creditsArray[cmeCheck] % 1 === 0)
				creditsArray[cmeCheck] = Number(creditsArray[cmeCheck]).toFixed(1);
			
			if (mocCheck > -1) { //if abim moc
				creditsMarkup += '<span class="abim-credits"><span class="credits-number">'+creditsArray[mocCheck]+'</span>ABIM MOC</span>';
				mocCounter++;
			}
			if (ceCheck > -1) { //if ce
				if (cmeCheck > -1) { //if ce & cme
					if (mocCounter > 0) {
						creditsMarkup += '<span class="activity-data-divider div-one"></span>';
						creditsMarkup += '<span class="primary-credits"><span class="credits-number">'+creditsArray[cmeCheck]+'</span>CME/CE</span>'
					}
					else {
						creditsMarkup += '<span class="primary-credits"><span class="credits-number">'+creditsArray[cmeCheck]+'</span>CME/CE</span>'				
					}
				}
				else { //if just ce
					if (mocCounter > 0) {
						creditsMarkup += '<span class="activity-data-divider div-one"></span>';
					}
					creditsMarkup += '<span class="primary-credits"><span class="credits-number">'+creditsArray[ceCheck]+'</span>CE</span>'
					mocCounter++;
				}
			}
			else if (cmeCheck > -1) { //if cme (when there isn't ce)
				if (mocCounter > 0) {
					creditsMarkup += '<span class="activity-data-divider div-one"></span>';
				}		
				creditsMarkup += '<span class="primary-credits"><span class="credits-number">'+creditsArray[cmeCheck]+'</span>CME</span>'
				mocCounter++;
			}
			else {
				creditsMarkup += '<span class="primary-credits"><span class="credits-number">'+creditsArray[0]+'</span>Credits</span>'
			}		
			
			
			creditsMarkup += '<span class="activity-data-divider div-two"></span>';
			creditsMarkup += '<span class="length"><span class="credits-number">'+highestLength+'</span>MIN</span>'
			return creditsMarkup
		},	
		bbMarkup : function() {
			var bbMarkup = '';
			$(saved.config.savedArray).each(function(i){
				var e = '<div class="saved-card-bb">';
				e += '<span class="meta-info">';
				e += '<span class="meta-info-credit-type">'+this.jsonData.credittype+'</span>';
				e += '<span class="meta-info-credits">'+this.jsonData.credits+'</span>';
				e += '<span class="meta-info-tx-num">'+this.jsonData.userTxnId+'</span>';
				e += '<span class="meta-info-pri-accr-id">'+this.jsonData.priAccreditorId+'</span>';
				e += '</span>';
				e += '<div class="activityTitle"><a href="'+this.jsonData.url+'" class="title">'+this.jsonData.title+'</a></div>';
				e += '<div class="byline">'+this.jsonData.specialty+'<span class="posting-date">'+this.jsonData.date+'</span></div>';
				e += '<div class="activityData">'+saved.theAvailCredits(this);
				if (this.jsonData.priAccreditorId != 1073) {
					e += '<span class="activity-data-divider div-two"></span>'
				}
				e += '<div class="saveState saved" id="txn'+this.userTxnId+'-bb"><span class="star-blue"></span><br>Saved</div>';
				e += '</div>';
				e += '</div>';
				bbMarkup += e;
			});
			if (Number(saved.config.count) > 3) {
				$('.three-or-less').removeClass('three-or-less');
				bbMarkup += '<span class="view-more-saved"><a href="/sites/saved">View All ('+saved.config.count+')</a></span>';
			}
			else {
				$('.saved-modal').addClass('three-or-less');
			}
			saved.config.dropDownMarkup = bbMarkup;
			$('.saved-modal').html(bbMarkup);
			saved.hideEmptyCreditInfo();
		},
		hideEmptyCreditInfo : function() {
			$('.saved-card-bb').each(function(){
				var credits = $(this).find('.length .credits-number').text();
				let priAccreditorId = $(this).find('.meta-info-pri-accr-id').text();
				if ( (credits <= 0 || isNaN(credits)) && priAccreditorId != 1073) {
					$(this).find("span[class$='-credits']").css("display", "none");
					$(this).find(".activity-data-divider").css("display", "none");
					$(this).find(".length").css("display", "none");
				}
			});
		}
	}


	var addBbLine = function(){
		$('.header-cme-rclist').addClass('bb');
	};

}


$(function(){
	if (typeof breakpoint !== 'undefined') {
		addBbLine();
		touchEnabled.init();
		menuItems.init();	
		
		
		//scroll check
		$( window ).on('scroll',function(){
			scrollEvents.stickyHeaderCheck();
		});
		
		
		//sticky class for iphone or ipod
		
		if (navigator.userAgent.match(/iPad/i) != null || navigator.userAgent.match(/iPhone/i) != null) {
			$('body').addClass('supports-sticky');
		}
		
		
		//bind elements
		brandingBinds();
	}		
});

const barItem = {
	init() {
		if($("#brandingBarPlaceholder").length) {

			this.insertBrandingBar();
			setTimeout(() => {
				this.attachEventListeners();
			}, 1000);
		}
	},
      
	insertBrandingBar() {
	const baseURL = window.location.origin || "//www.medscape.org";
	const brandingBarHTML = `
	    <div id="brandingBarItem" class="tooltip-container">
	    <div class="bar-wrapper">
	      <span>
	      <a href="${baseURL}">Medscape.org</a> provides accredited, ad-free, and evidence-based independent education 
	      </span>
	      <span class="icon-svg" data-tooltip="Medscape Education is a grant-funded group within the Medscape organization that maintains editorial independence to ensure unbiased educational material." data-tooltip-position="bottom">
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8.5C1 4.63401 4.13401 1.5 8 1.5C11.866 1.5 15 4.63401 15 8.5C15 10.4852 14.1742 12.2767 12.8461 13.5513C11.5879 14.7587 9.88104 15.5 8 15.5C4.13401 15.5 1 12.366 1 8.5ZM8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5C10.1493 16.5 12.1016 15.6517 13.5385 14.2728C15.055 12.8175 16 10.7687 16 8.5C16 4.08172 12.4183 0.5 8 0.5ZM8.75 4.25C8.75 4.66421 8.41421 5 8 5C7.58579 5 7.25 4.66421 7.25 4.25C7.25 3.83579 7.58579 3.5 8 3.5C8.41421 3.5 8.75 3.83579 8.75 4.25ZM7.5 13.0001L7.4993 7.00006C7.49927 6.72392 7.7231 6.50003 7.99924 6.5C8.27539 6.49997 8.49927 6.7238 8.4993 6.99994L8.5 12.9999C8.50003 13.2761 8.2762 13.5 8.00006 13.5C7.72392 13.5 7.50003 13.2762 7.5 13.0001Z" fill="white"></path>
		</svg>
	      </span>
	      </div>
	      <div id="tooltip" class="tooltip"><div class="tooltip_arrow"></div>Medscape Education is a grant-funded group within the Medscape organization that maintains editorial independence to ensure unbiased educational material.</div>
	    </div>
	  `;
	  $("#brandingBarPlaceholder").before(brandingBarHTML);
	  if($("body").has(".page-header.nrc").length && !$("body").hasClass("no-bb")) {
		$("#brandingBarItem").addClass('has-nrc');
	  }
	},
      
	attachEventListeners() {
	  
		$(document).on('click', (event) => {
				
				if($(event.target).closest('.icon-svg, #tooltip').length) {
					this.handleIconClick(event);	  
				}
				else {
					this.hideTooltip();
				}
				
		});

	  // Scroll event listener to hide brandingBarItem if out of view
	  $(window).on('scroll', function() {
		const brandingBarItem = $('#brandingBarItem');
		const rect = brandingBarItem[0].getBoundingClientRect();

		// Check if brandingBarItem is out of the viewport
		if (rect.bottom < 0 || rect.top > window.innerHeight) {
			brandingBarItem.addClass("no-view");
		} else {
			brandingBarItem.removeClass("no-view");
		}
	  });
	},
      
	handleIconClick(e) {
		// keeps checking for where the event source is coming from
	  if ($(e.target).closest('.icon-svg').length) {
	    e.stopPropagation();
	    const tooltip = $('#tooltip');
      
	    if (tooltip.is(':visible')) {
	      this.hideTooltip();
	    } else {
	      this.adjustTooltipPosition();
	      this.showTooltip();
	    }
	  }
	},
      
	adjustTooltipPosition() {
	  const svgLeft = $('.icon-svg').offset().left;
	  const windowWidth = window.innerWidth;
      
	  if (svgLeft + 10 < windowWidth) {
	    const tipRemain = windowWidth - svgLeft - (svgLeft > 50 ? 50 : 0);
	    if(window.matchMedia("(min-width: 768px)").matches) {
		$('#tooltip').css({ right: `${tipRemain}px` });
	    }
	  }
	},
      
	showTooltip() {
	  $('#tooltip').fadeIn(200);
	},
      
	hideTooltip() {
	  $('#tooltip').fadeOut(200);
	}
      };
      

//  Add client and wcode (additional parameters) to Login Header link in Medscape.org
function changeLogInUrl(){	$('a[href*="login/sso/getlogin"]').each(function(i){	let splittedUrl = $(this).attr('href').split('?');
	let newHref = '';	if(splittedUrl.length > 1)  {		newHref = splittedUrl[0] +'?wcode=102&client=205502&scode=msporg&' + splittedUrl[1];	}else{		newHref = $(this).attr('href') + '?wcode=102&client=205502&scode=msporg';	}	    $(this).attr('href', newHref);});}
$(document).ready(function(){
    changeLogInUrl();

    barItem.init();
});



